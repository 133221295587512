import { EmpresaService } from '@administracion/services/empresa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReporteCuentaCorrienteView } from '@entities/views/reportes/reporte-cuenta-corriente.view';
import { TablaComponent } from '@shared/components/tabla/tabla.component';
import { ReporteComponent } from '@shared/directives/abmc/reporte.directive';
import { ColumnaTabla } from '@shared/interfaces/columna-tabla.interface';
import { ReporteCuentaCorrienteService } from '@ventas/reportes/services/reporte-cuenta-corriente.service';
import { ClienteService } from '@ventas/services/cliente.service';
import { Observable, forkJoin, of } from 'rxjs';

@Component({
    selector: 'kratos-reporte-cuenta-corriente',
    templateUrl: './reporte-cuenta-corriente.component.html',
    styleUrls: ['./reporte-cuenta-corriente.component.scss'],
})
export class ReporteCuentaCorrienteComponent extends ReporteComponent<ReporteCuentaCorrienteView> implements OnInit {
    @ViewChild('tabla') public tabla!: TablaComponent;

    public titulo = 'Reporte: Cuenta Corriente';

    public columnas: ColumnaTabla[] = [
        {
            title: 'Empresa',
            data: 'empresaNombre',
            searchable: true,
            name: 'empresas',
            label: 'nombre',
        },
        {
            title: 'Cliente',
            data: 'clienteRazonSocial',
            searchable: true,
            name: 'clientes',
            label: 'razonSocial',
        },
        {
            title: 'Tipo',
            data: 'tipoComprobanteNombre',
            searchable: true,
        },
        {
            title: 'Fecha',
            data: 'fecha',
            tipo: 'date',
            searchable: true,
        },
        {
            title: 'Pto. Vta.',
            data: 'puntoVentaNumero',
            searchable: true,
        },
        {
            title: 'Número',
            data: 'numero',
            searchable: true,
        },
        {
            title: 'Importe Total',
            data: 'importeTotal',
            tipo: 'moneda',
            searchable: true,
        },
        {
            title: 'Saldo',
            data: 'saldo',
            tipo: 'moneda',
            searchable: false,
            orderable: false,
        },
    ];

    public constructor(
        protected reporteCuentaCorrienteService: ReporteCuentaCorrienteService,
        protected empresaService: EmpresaService,
        protected clienteService: ClienteService,
    ) {
        super(reporteCuentaCorrienteService);
    }

    public override ajaxHandler(event: any) {
        super.ajaxHandler(event, this.reporteCuentaCorrienteService);
    }

    protected obtenerRelaciones(): Observable<any> {
        return forkJoin({
            empresas: this.empresaService.obtenerTodos(),
            clientes: this.clienteService.obtenerTodos(),
        });
    }

    protected override cargarBusquedaAvanzada(): void {
        this.tablaOpciones.order = [[3, 'asc']];
        this.tablaOpciones.searchBuilder = {
            columns: ['Empresa', 'Cliente', 'Fecha'],
            preDefined: {
                criteria: [
                    {
                        data: 'Empresa',
                        origData: 'empresaNombre',
                        condition: '=',
                        value: [] as any[],
                    },
                    {
                        data: 'Cliente',
                        origData: 'clienteRazonSocial',
                        condition: '=',
                        value: [] as any[],
                    },
                ],
            },
        };
        this.tablaOpciones.searchBuilder.preDefined.criteria.forEach((criteria: any) => {
            if (criteria.data === 'Empresa') {
                // Si existe, tomar el valor de la empresa del perfil del usuario
                try {
                    criteria.value = [
                        JSON.parse(localStorage.getItem('empresa') ?? '{}')?.nombre ??
                            this.relaciones.empresas[0]?.nombre ??
                            '',
                    ];
                } catch (error) {
                    criteria.value = [this.relaciones.empresas[0]?.nombre ?? ''];
                }
            }
            // if (criteria.data === 'Cliente') {
            //     criteria.value = [this.relaciones.clientes[0].razonSocial];
            // }
        });
    }
}
